import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

export type ConnectionContextType = {
  isOnline: boolean;
  isPopupDismissed: boolean;
  handleDismissPopup: () => void;
};

export const ConnectionContext = createContext<
  ConnectionContextType | undefined
>(undefined);

interface ConnectionProviderProps {
  children: ReactNode;
}

const getOnlineStatus = () => {
  if (typeof navigator === 'undefined') {
    // Assume online status is true on the server
    return true;
  }
  return navigator.onLine;
};

export const ConnectionProvider = ({ children }: ConnectionProviderProps) => {
  const [isOnline, setOnlineStatus] = useState(getOnlineStatus());
  const [isPopupDismissed, setPopupDismissed] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const goOnline = () => {
      setOnlineStatus(true);
      setPopupDismissed(false);
    };

    const goOffline = () => setOnlineStatus(false);

    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  const handleDismissPopup = () => setPopupDismissed(true);

  const value = {
    isOnline,
    isPopupDismissed,
    handleDismissPopup,
  };

  return (
    <ConnectionContext.Provider value={value}>
      {children}
    </ConnectionContext.Provider>
  );
};

export const useConnection = () => {
  const context = useContext(ConnectionContext);

  if (!context) {
    throw new Error('useConnection must be used within a ConnectionProvider');
  }

  return context;
};
